/* eslint-disable import/first */
// CSS
import "normalize.css";
import "@talentpair/assets/css/main.css";
import "@talentpair/assets/css/basscss";
import { sentry } from "@talentpair/sentry";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import App from "./App";

const history = createBrowserHistory();

// Start this asap to catch any errors
sentry.init(history);

ReactDOM.render(<App history={history} />, document.getElementById("react-app"));
