export function isExternalUrl(url: string): boolean {
  try {
    const { hostname } = new URL(url);
    if (hostname.endsWith("talentpair.com") || hostname.endsWith("tpair.co")) return false;
  } catch (err) {
    // new URL() expects an absolute url, and so will error when passed a relative url like: `/home?foo=bar`
    return false;
  }
  return true;
}
