import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "@talentpair/routing";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export type BackLinkProps = RouteComponentProps<
  Record<string, string | undefined>,
  Record<string, unknown>,
  { backLink?: string }
>;

function BackLink({ location: { state } }: BackLinkProps): React.ReactElement {
  return (
    <Link className="color-inherit" to={state?.backLink || "/"}>
      <IconButton color="inherit" style={{ marginLeft: -12 }}>
        <ArrowBackIcon />
      </IconButton>
    </Link>
  );
}

export default withRouter(BackLink);

export const _test = { BackLink };
