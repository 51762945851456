import { createReferralRoute } from "@talentpair/tracking";
import { queenstownLink } from "@talentpair/env";

type JobReferralParams = {
  referral_code: string;
  job_id: string;
  job_slug?: string;
};

export const JobReferralPage = createReferralRoute<JobReferralParams>((params) =>
  queenstownLink.jobDetail(params.job_id, params.job_slug),
);
