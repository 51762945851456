import * as React from "react";
import { RouteComponentProps } from "@talentpair/routing";
import JobBoardPage from "../components/JobBoardPage";
import { OrgBoardDetailT } from "../types";

interface JobListPageProps extends RouteComponentProps<{ slug?: string }> {
  org: OrgBoardDetailT;
  slug: string;
}

const Page = React.lazy(() => import("./JobBoardList"));

export default (props: JobListPageProps): React.ReactElement => (
  <JobBoardPage page={Page} {...props} />
);
