import * as React from "react";
import { RouteComponentProps } from "@talentpair/routing";
import JobBoardPage from "../components/JobBoardPage";
import BackLink from "../components/BackLink";
import { OrgBoardDetailT } from "../types";

interface OrganizationDetailPageProps extends RouteComponentProps<{ company?: string }> {
  org: OrgBoardDetailT;
  slug: string;
}

const Page = React.lazy(() => import("./OrganizationDetail"));

export default ({ match, org, slug }: OrganizationDetailPageProps): React.ReactElement => (
  <JobBoardPage
    showFooter
    leftAction={org.job_count > 10 || slug === "tp" ? <BackLink /> : undefined}
    page={Page}
    org={org}
    companyId={+(match.params.company || org.id)}
    slug={slug}
  />
);
