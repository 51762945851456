import { queryStrToObj } from "@talentpair/utils";
import { setTpCookie } from "./cookie";

interface TalentpairReferralParams {
  referral_code?: string;
}

export function checkAndSetReferralCookies(
  params: TalentpairReferralParams = queryStrToObj(window.location.search),
): void {
  if (params.referral_code) setTpCookie("REFERRAL_CODE", params.referral_code);
}
