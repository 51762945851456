import * as React from "react";
import withStyles from "@mui/styles/withStyles";
import { AppBar, Toolbar, Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { userService, useAuth } from "@talentpair/api";
import { env, londonLink } from "@talentpair/env";
import { Loader, Button, Typography } from "@talentpair/quantic";
import { ErrorBoundary } from "@talentpair/sentry";
import AuthButtons from "kyoto/components/AuthButtons";
import { OrgBoardDetailT } from "../types";

const CandidateHeaderActions = React.lazy(() => import("kyoto/components/CandidateHeaderActions"));

export const Content = withStyles({
  item: {
    display: "flex",
    minHeight: "calc(100vh - 56px)",
    marginTop: 56,
    "@media (min-width:0px) and (orientation: landscape)": {
      minHeight: "calc(100vh - 48px)",
      marginTop: 48,
    },
    "@media (min-width:600px)": {
      minHeight: "calc(100vh - 64px)",
      marginTop: 64,
    },
  },
})(Grid);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface JobBoardPageProps extends Record<string, any> {
  org: OrgBoardDetailT;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page: React.LazyExoticComponent<any>;
  leftAction?: React.ReactNode;
  slug: string;
}

export default function JobBoardPage({
  page: Page,
  leftAction,
  ...props
}: JobBoardPageProps): React.ReactElement {
  const { state } = useAuth(); // hook into user auth so we update this component when the auth state changes
  const isCandidate = userService.isCandidate();
  const { org, slug } = props;
  const logo = org.logo_url ? (
    <img
      src={org.logo_url}
      alt={org.name}
      {...(slug !== "tp"
        ? { className: "appbar-icon" }
        : // our talentpair logo is not a nice square so requires special styling
          { style: { marginBottom: -5, width: 100 } })}
    />
  ) : (
    <div className="appbar-icon flex-center white bold h2">{org.name}</div>
  );
  return (
    <Grid>
      <AppBar elevation={0}>
        <Toolbar className="relative flex-center">
          <div className="flex-auto flex justify-between items-center">
            {leftAction ||
              (isCandidate ? (
                <Button
                  size="lg"
                  variant="icon"
                  icon={HomeIcon}
                  style={{ marginLeft: -8 }}
                  component="a"
                  href={env.londonUrl(londonLink.capPairList(userService.data?.candidate_id || 0))}
                />
              ) : (
                <div> </div>
              ))}
            {isCandidate ? (
              <React.Suspense fallback={<div />}>
                <CandidateHeaderActions
                  candidateId={userService.data?.candidate_id || 0}
                  showReferralLink
                  pathname={location.pathname}
                />
              </React.Suspense>
            ) : state !== "checking" ? (
              <AuthButtons />
            ) : null}
          </div>
          <div className="absolute flex-center">
            {slug !== "tp" ? (
              <a href={`http://${org.crunchbase_domain_name || ""}`} className="appbar-icon">
                {logo}
              </a>
            ) : isCandidate ? (
              <Typography variant="h2" color="primary.contrastText">
                Job board
              </Typography>
            ) : state === "checking" ? null : (
              logo
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Content item direction="column" container>
        <ErrorBoundary>
          <React.Suspense fallback={<Loader absolute />}>
            <Page {...props} />
          </React.Suspense>
        </ErrorBoundary>
      </Content>
    </Grid>
  );
}
