import * as React from "react";
import { RouteComponentProps } from "@talentpair/routing";
import JobBoardPage from "../components/JobBoardPage";
import BackLink from "../components/BackLink";
import { OrgBoardDetailT } from "../types";

interface JobDetailPageProps extends RouteComponentProps<{ job?: string }> {
  org: OrgBoardDetailT;
  slug: string;
}

const Page = React.lazy(() => import("./JobBoardDetail"));

export default ({ match, org, slug }: JobDetailPageProps): React.ReactElement => (
  <JobBoardPage
    showFooter
    leftAction={<BackLink />}
    page={Page}
    org={org}
    jobId={+(match.params.job || "")}
    slug={slug}
  />
);
