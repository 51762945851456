import * as React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { apiRequest } from "@talentpair/api";
import { NavigationAnalytics, checkAndSetReferralCookies, setTpCookie } from "@talentpair/tracking";
import { isExternalUrl } from "@talentpair/env";
import { Loader, tpTheme, MediaContextProvider } from "@talentpair/quantic";
import { TalentpairTheme } from "@talentpair/quantic/theme/types";
import { PortalRenderer } from "@talentpair/portal";
import { ErrorBoundary, Error500, sentry, SentryRoute, withProfiler } from "@talentpair/sentry";
import { Router, Redirect, Switch, History } from "@talentpair/routing";
import AppVersionPing from "kyoto/components/AppVersionPing";
import logo from "./img/logo-white.png";
import JobBoardListPage from "./JobBoardList";
import JobBoardDetailPage from "./JobBoardDetail";
import OrganizationDetailPage from "./OrganizationDetail";
import themeFromPrimary from "./theme";
import { OrgBoardDetailT } from "./types";
import { JobReferralPage } from "./referralPage";

const routes = new Map([
  ["TP/PartnerJobsHome", { key: "TP/PartnerJobsHome", path: "/", exact: true }],
  ["TP/PartnerOrgDetail", { key: "TP/PartnerOrgDetail", path: "/about-us", exact: true }],
  ["TP/PublicJobsList", { key: "TP/PublicJobsList", path: "/:slug([a-zA-Z\\-]+)?", exact: true }],
  [
    "TP/PublicJobsDetail",
    { key: "TP/PublicJobsDetail", path: "/:job(\\d+)/:jobSlug?", exact: true },
  ],
  [
    "TP/PublicOrgDetail",
    { key: "TP/PublicOrgDetail", path: "/company/:company([0-9]+)", exact: true },
  ],
  ["REFERRAL/JobReferral", { path: "/referral/:referral_code/:job_id/:job_slug?", exact: true }],
]);

interface AppStateT {
  slug: string;
  org: null | OrgBoardDetailT;
  theme: TalentpairTheme;
  error: boolean;
}

interface PropsT {
  history: History;
}

class App extends React.Component<PropsT, AppStateT> {
  state: AppStateT;

  constructor(props: PropsT) {
    super(props);
    let slug = window.location.hostname.split(".")[0];
    if (!slug || ["localhost", "jobs"].includes(slug)) {
      if (window.location.hash) {
        slug = window.location.hash.substring(1);
      } else {
        slug = "tp";
      }
    }
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      slug,
      org: null,
      theme: tpTheme,
      error: false,
    };
  }

  componentDidMount(): void {
    const { slug } = this.state;
    const notPublic = slug !== "tp";
    (notPublic
      ? apiRequest
          .getMemoized<OrgBoardDetailT>(`organizations/board-detail/${slug}/`)
          .then((r) => r.data)
      : Promise.resolve({
          header: "Browse startup jobs on Talentpair",
          is_vc: false,
          id: 2,
          logo_url: logo,
          name: "Talentpair",
          story: "",
          size: null,
          year: null,
          slug: "tp",
          crunchbase_domain_name: null,
          job_board_color: "",
          job_board_header: "",
          // We don't really know the job_count at this point, but we say more than 100 to ensure we don't use the wrong layout
          job_count: 100,
        } as OrgBoardDetailT)
    )
      .then((org) =>
        this.setState({
          org,
          theme: notPublic && org.job_board_color ? themeFromPrimary(org.job_board_color) : tpTheme,
        }),
      )
      .catch((error: Error) => {
        this.setState({ error: true });
        sentry.error(error);
      });
    // capture 3rd party referrers and set as a cookie for BE to read
    if (isExternalUrl(document.referrer))
      setTpCookie("JOB_BOARD_REFERER", new URL(document.referrer).hostname);
    // capture any user referrals
    checkAndSetReferralCookies(); // TODO: Remove this line after June 11th 2024 as all our referral links should be using a referral-specific route
  }

  render(): React.ReactElement {
    const { history } = this.props;
    const { slug, error, org, theme } = this.state;
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MediaContextProvider>
            {error ? (
              <Error500 />
            ) : !org ? (
              <Loader absolute />
            ) : (
              <Router history={history}>
                <ErrorBoundary>
                  <Helmet>
                    <title>{org.name} Jobs</title>
                  </Helmet>
                  <CssBaseline />
                  <Switch>
                    <SentryRoute
                      {...routes.get("REFERRAL/JobReferral")}
                      component={JobReferralPage}
                    />
                    {slug !== "tp" ? (
                      // When partners have 10 or fewer jobs posted then showing the full searchable list with filters is overkill
                      // Otherwise the partner/vc board has the about-us route
                      // All ultimately resolve to the same component
                      <SentryRoute
                        {...routes.get(
                          org.job_count > 10 || org.is_vc
                            ? "TP/PartnerOrgDetail"
                            : "TP/PartnerJobsHome",
                        )}
                        render={(props) => (
                          <OrganizationDetailPage {...props} org={org} slug={slug} />
                        )}
                      />
                    ) : null}
                    <SentryRoute
                      {...routes.get("TP/PublicJobsList")}
                      render={(props) => <JobBoardListPage {...props} org={org} slug={slug} />}
                    />
                    {slug === "tp" || org.is_vc ? (
                      <SentryRoute
                        {...routes.get("TP/PublicOrgDetail")}
                        render={(props) => (
                          <OrganizationDetailPage {...props} org={org} slug={slug} />
                        )}
                      />
                    ) : null}
                    <SentryRoute
                      {...routes.get("TP/PublicJobsDetail")}
                      render={(props) => <JobBoardDetailPage {...props} org={org} slug={slug} />}
                    />
                    <Redirect to="/" />
                  </Switch>
                  <AppVersionPing app="queenstown" />
                  <NavigationAnalytics skipSignupRedirect={() => true} routes={routes} />
                  <PortalRenderer />
                </ErrorBoundary>
              </Router>
            )}
          </MediaContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withProfiler(App);
